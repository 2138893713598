@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Barcode+39+Text&family=Pacifico&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Barcode+39+Text&family=Pacifico&family=Righteous&family=Yanone+Kaffeesatz:wght@400&display=swap');

/* -------------Login-------------*/

.auth_page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background: #27004b;
    background-image: url("../images/burger4_01-scaled.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.auth_page::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #27004b;
    opacity: 0.9;
    z-index: -1;
    overflow: hidden;
}

.auth_page * {
    position: relative;
    z-index: 2;
}

.auth_page .logo {
    height: 25%;
    width: 35%;
    margin-left: 15%;
    margin-top: 0;
    left: 0;
    opacity: 0;
    animation: fadeRight 0.9s forwards;
    animation-delay: 0.9s;
    position: fixed;
}

.auth_page .footer h1 {
    color: #fff500;
    font-size: 100px;
    margin-left: 20%;
    font-family: 'Dancing Script', cursive;
    font-family: 'Libre Barcode 39 Text', cursive;
    font-family: 'Pacifico', cursive;
    font-family: 'Righteous', cursive;
    opacity: 0;
    animation: fadeRight 1s forwards;
    animation-delay: 1s;
}

.auth_page input {
    cursor: pointer;
}

.auth_page .footer h6 {
    color: white;
    text-decoration: "none";
    margin-top: -5%;
    margin-left: 20%;
    font-size: 30px;
    font-family: 'Dancing Script', cursive;
    font-family: 'Libre Barcode 39 Text', cursive;
    font-family: 'Pacifico', cursive;
    font-family: 'Righteous', cursive;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    opacity: 0;
    animation: fadeDown 1.5s forwards;
    animation-delay: 1.5s;
}

.auth_page form {
    background: #f6f6f6c7;
    position: relative;
    max-width: 400px;
    width: 100%;
    margin-left: 45%;
    padding: 50px 25px;
    border-radius: 10px;
    box-shadow: 2px 2px 15px #fff500;
    opacity: 0;
    animation: fadeRight 0.5s forwards;
    animation-delay: 0.5s;
}

.auth_page form .pass{
    position: relative;
}

.auth_page form .pass small{
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
}

.auth_page .footer .creator {
    color: white;
    font-size: 15px;
    opacity: 0;
    animation: fadeUp 1s forwards;
    animation-delay: 1s;
}

.auth_page .instruction {
    left: 0;
    margin-bottom: 5%;
    background-color: #fff500;
    width: 100%;
    border: none;
    border-radius: 10px;
    text-align: center;
    padding: 1%;
}

.auth_page .footer .creator a {
    text-decoration: none;
    background-color: #fff500;
    color: #27004b;
    border-radius: 50px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: bold;
    animation: blink 1s linear infinite;
}

.auth_page .footer .creator a:hover {
    animation: none;
}

/*---------Registration----------*/

.auth_page_reg {
    width: 100%;
    min-height: 100vh;
    background: #27004b;
    /* background-image: url("../images/young-people-dining-having-fun-drinking-red-wine-together-balcony-rooftop-dinner-party-happy-friends-eating-bbq-food-restaurant-patio-millannial-life-style-concept_101731-789.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_page_reg .footer h1 {
    color: #fff500;
    font-size: 100px;
    margin-left: 20%;
    font-family: 'Dancing Script', cursive;
    font-family: 'Libre Barcode 39 Text', cursive;
    font-family: 'Pacifico', cursive;
    font-family: 'Righteous', cursive;
    opacity: 0;
    animation: fadeRight 0.8s forwards;
    animation-delay: 0.8s;
}

.auth_page_reg .footer h6 {
    color: white;
    text-decoration: "none";
    margin-top: -5%;
    margin-left: 20%;
    font-size: 30px;
    font-family: 'Dancing Script', cursive;
    font-family: 'Libre Barcode 39 Text', cursive;
    font-family: 'Pacifico', cursive;
    font-family: 'Righteous', cursive;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    opacity: 0;
    animation: fadeDown 1.2s forwards;
    animation-delay: 1.2s;
}

.auth_page .form-group #country {
    border: 2px solid #27004b;
    padding: 0%;
    padding-left: 1%;
    font-weight: bold;
}

.auth_page .form-group .option {
    background-color: #27004b;
    color: #fff500;
}

.auth_page_reg form{
    background: #ffffff;
    position: relative;
    max-width: 400px;
    width: 100%;
    margin-left: 15%;
    padding: 50px 25px;
    border-radius: 10px;
    box-shadow: 2px 2px 20px black;
    opacity: 0;
    animation: fadeRight 0.5s forwards;
    animation-delay: 0.5s;
}

.auth_page_reg form .pass{
    position: relative;
}

.auth_page_reg form .pass small{
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
}

.auth_page_reg .footer .creator {
    color: white;
    font-size: 15px;
    opacity: 0;
    animation: fadeUp 1s forwards;
    animation-delay: 1s;
}

.auth_page_reg .footer .creator a {
    text-decoration: none;
    background-color: #fff500;
    color: #27004b;
    border-radius: 50px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: bold;
    animation: blink 1s linear infinite;
}

.auth_page_reg .footer .creator a:hover {
    animation: none;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeRight {
    0% {
        transform: translateX(4rem);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeUp {
    0% {
        transform: translateY(4rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeDown {
    0% {
        transform: translateY(-1rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 766px){
    .auth_page {
        position: fixed;
        margin-top: 0%;
        min-height: 110vh;
    }

    .auth_page .logo {
        height: 10%;
        width: 70%;
        left: 0;
        top: 0;
        margin-left: 14%;
        margin-top: 15%;
        opacity: 0;
        animation: fadeDown 0.8s forwards;
        animation-delay: 0.8s;
        position: fixed;
    }

    .auth_page .footer h1 {
        top: 0;
        right: 0;
        margin-top: 10%;
        margin-right: 14%;
        font-size: 50px;
        text-align: center;
        position: fixed;
    }

    .auth_page .footer h6 {
        /* display: none; */
        top: 0;
        right: 0;
        margin-right: 38%;
        font-size: 20px;
        margin-top: 25%;
        position: fixed;
    }

    .auth_page form{
      right: 0;
      margin-right: 5%;
      width: 90%;
      margin-bottom: 10%;
      position: fixed;  
      padding-left: 3%;
      padding-right: 3%;
      padding-top: 5%;
      padding-bottom: 4%;
    }

    .auth_page .registartion {
        width: 90%;
        position: fixed;
        right: 0;
        border-radius: 10px;
    }
  }