.message {
    width: 100%;
    height: calc(100vh - 80px);
    border: none;
    margin-top: 1px;
    position: fixed;
}

.message_header{
    width: 100%;
    height: 60px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5%;
    /* background: #27004b; */
}

.message_header .input {
    background-color: #27004b;
    color: white;
    padding-left: 5%;
}

.message_header_right {
    width: 100%;
    height: 60px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #27004b;
    margin-top: 0.2%;
}

.message-image {
    cursor: pointer;
    width: 350px;
    margin-left: 0;
    margin-right: 0;
    border: none;
}

.msg_display {
    background-color: #f1f1f1;
    margin-top: 2%;
    width: 30%;
    padding: 1%;
    margin-left: 1%;
    margin-bottom: -2%;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #27004b;
}

.message_header input{
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    background: #f8f8f8;
    padding: 0 5px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.message_chat_list{
    width: 100%;
    height: 90vh;
    overflow-y: auto;
    /* background-color: #27004b; */
    background-image: url('../images/burger4_01-scaled.jpg');
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

.message_chat_list .message_user {
    background-color: #f1f1f1e8;
    margin-top: 2%;
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(39, 0, 75, 0.5);
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.message_chat_list .message_user:hover {
    box-shadow: 8px 8px 15px #27004b;
}

.message_chat_list .message_user a {
    color: #27004b;
    text-decoration: none;
    transition: color 0.3s ease;
}
   
.message_chat_list .message_user a:hover {
    text-decoration: none;
}

.message_chat_list .message_user .fa-circle{
    font-size: 15px;
    color: #aaa;
}
.message_chat_list .message_user .fa-circle.active{
    color: forestgreen;
}

.chat_container {
    /* background-image: url('../images/burger4_01-scaled.jpg'); */
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    padding: 20px;
    margin-top: 0;
    margin-bottom: 0;
}

/* -------- Chat Input----- */
.chat_input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-top: 1px solid #ddd;
}

.chat_input input{
    width: 88%;
    height: 50px;
    border: none;
    outline: none;
}
.chat_input button{
    border: none;
    outline: none;
    background: white;
}

.chat_input .file_upload{
    position: relative;
    overflow: hidden;
    margin: 0 10px;
}
.chat_input .file_upload #file{
    position: absolute;
    top:0;
    left: 0;
    opacity: 0;;
}
.show_media{
    width: 100%;
    height: 70px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    place-items: center;
    grid-gap: 10px;
    background: #f3f3f3;
    border-radius: 5px;
    padding: 0 5px;
}
.show_media #file_media{
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
}
.show_media #file_media img,
.show_media #file_media video{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}
.show_media #file_media span{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    background: white;
    border: 1px solid crimson;
    padding: 3px 7px;
    color: crimson;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
}

/* -------- Chat Container ----- */
.chat_container {
    position: relative; /* Ensure positioning for the pseudo-element to work correctly */
    width: 100%;
    height: calc(100% - 105px);
    overflow-y: auto;
    padding: 0 10px;
    margin-top: 0;
    background-color: #0008;
}

.chat_display{
    width: 100%;
    min-height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.chat_row{
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 20px;
}
.you_message{
    justify-content: start;
    justify-items: start;
}
.other_message{
    justify-content: start;
    justify-items: start;
}
.chat_text{
    padding: 9px 12px;
    margin-bottom: 8px;
}
.chat_time{
    font-size: 13px;
    color: #777;
    /* margin-bottom: 55px; */
}
.you_message .chat_text{
    background: #27004b;
    color: white;
    border: 1px solid #27004b;
    border-radius: 14px 14px 0 14px;
    box-shadow: 1px 1px 5px black;
    margin-left: auto; /* Push it to the right */
    margin-right: 0;
    font-weight: 500;
}
.other_message .chat_text{
    background: #fff500;
    color: black;
    font-weight: 500;
    border: 2px solid black;
    border-radius: 14px 14px 14px 0;
    box-shadow: 1px 1px 5px black;
}
.chat_title{
    display: inline-block;
    white-space: nowrap;
    margin-bottom: 5px;
    text-align: left; /* Align chat title to the right */
}
.you_content{
    position: relative;
    text-align: left; /* Align the content to the right */
}
.you_content .fa-trash{
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    opacity: 0;
}
.you_content:hover .fa-trash{
    opacity: 1;
}
.chat_input .dropdown-menu{
    transform: translate3d(-110px, -200px, 0px) !important;
}

/* Enlarging image */

.enlarge-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px); /* Blurs the background */
}

.enlarge-image-wrapper {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.enlarge-image {
    width: 100%;
    height: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    animation: grow 0.3s ease-in-out;
}

@keyframes grow {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media (max-width: 766px){
    .message_header{
        width: 90%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background: #27004b;
        margin-top: 10%;
        border-radius: 10px;
        margin-left: 5%;
    }

    .message_header .input {
        border-radius: 10px;
    }

    .msg_display {
        background-color: #f1f1f1;
        margin-top: 2%;
        width: 80%;
        margin-bottom: -3%;
        padding: 3%;
        margin-left: 0%;
        border-radius: 10px;
        box-shadow: 1px 1px 10px #27004b;
    }

    .message_header_right {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background: #27004b;
        margin-top: 0;
        padding-top: 4%;
    }

    .message_chat_list .message_user{
        /* background-color: rgba(255, 255, 255, 0.9); */
        margin-top: 2%;
        width: 90%;
        margin-left: 5%;
        border-radius: 10px;
        box-shadow: 5px 5px 10px #27004b;
    }

    .message_chat_list{
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-image: url("../images/burger4_01-scaled.jpg");
        background-size: cover;
        background-position: center;
        margin-top: -20%;
        padding-top: 25%;
    }

    .message-image {
        cursor: pointer;
        width: 250px;
        margin-left: 0;
        margin-right: 0;
        border: none;
    }

    .chat_input{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: white;
        margin-bottom: 9%;
        font-weight: 100;
    }

    .chat_input input{
        width: 70%;
        height: 50px;
        border: none;
        outline: none;
    }

    .call_modal .call_box {
        background-color: #27004b;
        box-shadow: 2px 2px 20px white;
        width: 80%;
    }

    .message_chat_list .message_user a{
        color: black;
    }

    .message{
        margin-top: 20px;
        position: fixed;
        overflow-y: auto;
        background-image: url("../images/burger4_01-scaled.jpg");
        background-size: cover;
        background-position: center;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .message_header{
        margin-top: 2%;
    }
    
    .message_header_right {
        margin-top: 1%;
    }
}
